import React from 'react';
import { useLocation } from 'react-router-dom';

import styles from './index.module.scss';

interface SimpleProps {
  children: React.ReactNode
}

export default function AuthSteps(): React.ReactNode {
  const location = useLocation();

  return (
    <div>
      {location.pathname === '/sign-in' || location.pathname === '/' || location.pathname === '/forgot-password'
      || location.pathname === '/reset-password/'
        ? (<h3>Spare Ziet und Gäld!</h3>)
        : (
          <div className={styles.steps}>
            <div className={styles.step}>
              <div className={styles.stepsItem}>
                <div className={styles.stepIcon} style={{ backgroundColor: '#1677FF' }}>1</div>
                <span>Personal info</span>
              </div>
              <div className={styles.descriptions}>
                Please enter your information and proceed to the next step so we can build your account.
              </div>
            </div>
            <div className={styles.step}>
              <div className={styles.stepsItem}>
                <div className={styles.stepIcon}>2</div>
                <span>Account verification</span>
              </div>
              <div className={styles.descriptions}>
                Please verify your email and phone number.
              </div>
            </div>
            <div className={styles.step}>
              <div className={styles.stepsItem}>
                <div className={styles.stepIcon}>3</div>
                <span>Account is created</span>
              </div>
              <div className={styles.descriptions} style={{ border: 'none' }}>
                You’re all set to take control, manage your finances, and reach your goals with confidence!
              </div>
            </div>
          </div>
        )}
    </div>
  );
}
