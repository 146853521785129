import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import OTPVerification from '../../../../Common/Auth/OTPVerification';
import AlertSuccess from '../Form/Alert/Success';
import { useAuth } from '../../../../../store/auth';

import styles from '../../SignIn/OTPCode/index.module.scss';

interface OTPCodeProps {
  email?: string;
  onBack: () => void;
}

function OTPCode({ email, onBack }: OTPCodeProps): React.ReactNode {
  const {
    authorized, OTP,
  } = useAuth();
  const [firstAuthorized, setFirstAuthorized] = useState<boolean>(!authorized);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (authorized && firstAuthorized && !OTP.error && !OTP.response) {
      navigate('/');
    }
  }, [firstAuthorized, authorized, OTP.error, OTP.response]);

  useEffect(() => {
    if (!OTP.error && OTP.response) {
      setFirstAuthorized(true);
    }
  }, [OTP.error, OTP.response]);

  return (
    <div
      className={styles.wrapper}
    >
      <OTPVerification
        email={email}
        nameBtnFinish="Verify"
        alertSuccess={<AlertSuccess response={OTP.response} />}
        // afterBtnFinish={(
        //   <Button
        //     type="text"
        //     onClick={onBack}
        //     size="large"
        //     style={{ color: '#8C8C8C' }}
        //     icon={<LeftOutlined />}
        //   >
        //     {t('Back')}
        //   </Button>
        // )}
      />
    </div>
  );
}

export default OTPCode;
