import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Input, Form as AntdForm, Tooltip, DatePicker,
  Checkbox,
} from 'antd';
import { Action } from '@triare/auth-redux';
import { AnyObject, Error } from '@triare/auth-redux/dist/saga/common';
import { SignUpAction, signUp as signUpAction } from '@triare/auth-redux/dist/saga/auth/signUp';
import { useTranslation } from 'react-i18next';
import {
  CheckCircleFilled, CloseCircleOutlined, QuestionCircleOutlined,
} from '@ant-design/icons';
import { RootState } from '../../../../../store';
import { validationSignUp, createRulesForAntd } from '../../../../../utils/validations';
import { moduleName } from '../../../../../store/auth';
import PhoneNumberInput from '../../../../Common/PhoneNumberInput';
import Form from '../Form';
import { ContentProps } from '../index';
import Footer from '../Footer';

import styles from './index.module.scss';

interface FullRowsProps extends ContentProps {
  authorized: boolean;
  response: AnyObject | null;
  error: Error | null;
  signUp: (payload: SignUpAction) => Action;
}

const validationRules = createRulesForAntd(validationSignUp);

function FullRows({
  signUp, response, onSubmit, error,
}: FullRowsProps): React.ReactNode {
  const [signUpData, setSignUpData] = useState<SignUpAction | null>(null);
  const { t } = useTranslation();
  const [isTooltipVisible, setTooltipVisible] = useState(false);
  const [password, setPassword] = useState('');

  const rules = [
    { isValid: password.length >= 8, text: '8 characters minimum' },
    { isValid: /[a-z]/.test(password), text: 'One lowercase character' },
    { isValid: /[A-Z]/.test(password), text: 'One uppercase character' },
    { isValid: /\d/.test(password), text: 'One number' },
    { isValid: /[!"#$%&()*,.:<>?@^{|}]/.test(password), text: 'One special character' },
  ];

  useEffect(() => {
    if (!error && response && onSubmit) {
      onSubmit(signUpData);
    }
  }, [response, error, onSubmit]);

  return (
    <>
      <Form<SignUpAction>
        validationRules={validationRules}
        signUp={({ confirmPassword, ...props }) => {
          const data: SignUpAction = {
            ...props,
            // _query: {
            //   lang: 'en',
            // },
          };

          setSignUpData(data);
          signUp(data);
        }}
        name="signUpFullInfo"
      >
        <AntdForm.Item
          className="custom-form-item"
          name="firstName"
          label={(
            <span>
              {t('firstName')}
              {' '}
              <Tooltip title="Info text">
                <QuestionCircleOutlined className="tooltip" />
              </Tooltip>
            </span>
          )}
          rules={[validationRules]}
        >
          <Input
            placeholder={t('firstName') || 'First name'}
            size="large"
          />
        </AntdForm.Item>

        <AntdForm.Item
          className="custom-form-item"
          name="lastName"
          label={(
            <span>
              {t('lastName')}
              {' '}
              <Tooltip title="Info text">
                <QuestionCircleOutlined className="tooltip" />
              </Tooltip>
            </span>
          )}
          rules={[validationRules]}
        >
          <Input
            placeholder={t('lastName') || 'Last name'}
            size="large"
          />
        </AntdForm.Item>

        <AntdForm.Item
          className="custom-form-item"
          name="email"
          label={`${t('email')}`}
          rules={[validationRules]}
        >
          <Input
            placeholder={t('email') || 'Email'}
            size="large"
            readOnly
            onFocus={(e) => e.currentTarget.removeAttribute('readonly')}
          />
        </AntdForm.Item>

        <AntdForm.Item
          className="custom-form-item"
          name="password"
          label={`${t('password')}`}
          rules={[validationRules]}
        >
          <div>
            <Input.Password
              placeholder={t('password') || 'Password'}
              size="large"
              onFocus={() => setTooltipVisible(true)}
              onBlur={() => setTooltipVisible(false)}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Tooltip
              open={isTooltipVisible}
              placement="right"
              title={(
                <div className={styles.passwordTooltip}>
                  <p>Password must contain at least:</p>
                  {rules.map(({ isValid, text }) => (
                    <div key={text} className={isValid ? styles.valid : styles.invalid}>
                      {isValid ? <CheckCircleFilled /> : <CloseCircleOutlined className={styles.closeIcon} />}
                      <p>{text}</p>
                    </div>
                  ))}
                </div>
              )}
            />
          </div>
        </AntdForm.Item>

        <AntdForm.Item
          className="custom-form-item"
          name="confirmPassword"
          label={`${t('confirmPassword')}`}
          dependencies={['password']}
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }

                // eslint-disable-next-line prefer-promise-reject-errors
                return Promise.reject('Passwords that you entered do not match!');
              },
            }),
          ]}
        >
          <Input.Password
            placeholder={t('confirmPassword') || 'Confirm password'}
            size="large"
          />
        </AntdForm.Item>
        <AntdForm.Item
          className="custom-form-item"
          name="birthDate"
          label={(
            <span>
              {t('Birth Date')}
              {' '}
              <Tooltip title="Info text">
                <QuestionCircleOutlined className="tooltip" />
              </Tooltip>
            </span>
          )}
        >
          <DatePicker
            size="large"
            style={{ width: '100%' }}
            format="DD.MM.YYYY"
          />
        </AntdForm.Item>

        <AntdForm.Item
          className="custom-form-item"
          name="phone"
          label={(
            <span>
              {t('contactPhone')}
              {' '}
              <Tooltip title={t('Info text')}>
                <QuestionCircleOutlined className="tooltip" />
              </Tooltip>
            </span>
          )}
          rules={[validationRules]}
        >
          <PhoneNumberInput
            placeholder="321567926"
            // placeholder={t('phone') || 'Phone'}
            size="large"
          />
        </AntdForm.Item>
        <div style={{ display: 'flex', gap: '16px' }}>
          <AntdForm.Item
            name="zipCode"
            className="custom-form-item"
            label={(
              <span>
                {t('Zip code')}
                {' '}
                <Tooltip title="Info text">
                  <QuestionCircleOutlined className="tooltip" />
                </Tooltip>
              </span>
                    )}
            rules={[validationRules]}
          >
            <Input size="large" style={{ width: '100%' }} />
          </AntdForm.Item>
          <AntdForm.Item
            className="custom-form-item"
            label={(
              <span>
                {t('City')}
                {' '}
                <Tooltip title={t('Info text')}>
                  <QuestionCircleOutlined className="tooltip" />
                </Tooltip>
              </span>
                    )}
          >
            <Input size="large" style={{ width: '100%' }} />
          </AntdForm.Item>
        </div>
        <AntdForm.Item
          name="agreeToTerms"
          valuePropName="checked"
          rules={[validationRules]}
        >
          <Checkbox>
            {t('I have read and accept')}
            {' '}
            <a href="#">Privacy Policy</a>
            {' '}
            and
            {' '}
            <a href="#">Terms of Use</a>
          </Checkbox>
        </AntdForm.Item>
      </Form>
      <Footer />
    </>
  );
}

export default connect((state: RootState) => ({
  authorized: state[moduleName].authorized,
  response: state[moduleName].signUp.response,
  error: state[moduleName].signUp.error,
}), {
  signUp: signUpAction,
})(FullRows);
