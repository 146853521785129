import React from 'react';
import { useDispatch } from 'react-redux';
import { Alert } from 'antd';
import { AnyObject } from '@triare/auth-redux/dist/saga/common';
import { AlertProps } from 'antd/es/alert';
import { useAuth } from '../../../../../../../store/auth';

export interface AlertSuccessProps extends AlertProps {
  disable?: boolean;
  response: AnyObject | null;
}

function AlertSuccess({
  disable = false,
  response,
  ...props
}: AlertSuccessProps): React.ReactNode {
  const { authorized, signIn: { error }, user } = useAuth();
  const dispatch = useDispatch();

  if (disable === false && authorized && user && !error && response) {
    return (
      <Alert
        type="success"
        message="Signed in successfully!"
        closable
        // onClose={() => dispatch({
        //   type: actions.signInClear.toString(),
        // })}
        {...props}
      />
    );
  }

  return null;
}

export default AlertSuccess;
