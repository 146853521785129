import React, { useEffect, useState } from 'react';
import { SignUpAction, SignUpByPhoneAction, SignUpResponse } from '@triare/auth-redux/dist/saga/auth/signUp';
import { useAuth } from '../../../../store/auth';
import OTPCode from './OTPCode';
import Wrapper from '../Wrapper';

export interface ContentProps {
  onSubmit?: (values: SignUpAction | SignUpByPhoneAction | null) => void
}

export interface SignUpProps {
  content: React.FC<ContentProps>
}

export default function SignUp({ content: Content }: SignUpProps): React.ReactNode {
  const { signUp: { response } } = useAuth();
  const [state, setState] = useState<SignUpResponse>();
  const [requestData, setRequestData] = useState<SignUpAction | SignUpByPhoneAction | null>(null);

  useEffect(() => {
    if (response && response.secretKey) {
      setState(response);
    }
  }, [response]);

  return (
    <Wrapper>
      {state && state.secretKey && requestData ? (
        <OTPCode
          onBack={() => setState(undefined)}
          email={requestData?.email}
        />
      ) : (
        <>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <h1 style={{ margin: '0', fontSize: '38px' }}>Sign up</h1>
          </div>
          <Content onSubmit={setRequestData} />
        </>
        // <OTPCode
        //   onBack={() => setState(undefined)}
        //   email={requestData?.email}
        // />
      )}
    </Wrapper>
  );
}
