import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Alert, Button, Form, Input,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  confirmEmail as confirmEmailAction,
  confirmClear as confirmClearAction,
  confirmErrorClear as confirmErrorClearAction, ConfirmEmailAction,
} from '@triare/auth-redux/dist/saga/auth/confirm';
import { Action } from '@triare/auth-redux';
import Wrapper from '../Wrapper';
import { getMessageInError } from '../../../../hooks/fetch';
import { createRulesForAntd, validationNewPassword } from '../../../../utils/validations';
import Submit from '../../../Common/Form/Button/Submit';
import { useAuth } from '../../../../store/auth';
import OTPCode from '../SignUp/OTPCode';

import styles from './index.module.scss';

const validationRules = createRulesForAntd(validationNewPassword);

export interface ConfirmEmailProps {
  mode?: string;
  confirmEmail: (payload: ConfirmEmailAction) => Action<ConfirmEmailAction>;
  confirmClear: () => Action;
  confirmErrorClear: () => Action;
}

function ConfirmEmail({
  mode: modeParam, confirmEmail, confirmErrorClear, confirmClear,
}: ConfirmEmailProps): React.ReactNode {
  const { confirm: { response, loading, error }, user } = useAuth();
  const { t } = useTranslation();
  const { mode = modeParam, secretKey } = useParams();
  const navigate = useNavigate();
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    if (secretKey && mode === 'none') {
      confirmEmail({
        secretKey,
      });
    }
  }, [secretKey]);

  useEffect(() => {
    if (!loading && !error) {
      if (response) {
        setRedirect(true);
      } else if (redirect) {
        if (user && user.settings && !user.settings.isPhoneVerified) {
          console.log('bla');
        } else {
          navigate('/');
        }
      }
    }
  }, [response, error, loading, redirect, user]);

  if (user && user.email) {
    return (
      <Wrapper>
        <OTPCode onBack={() => navigate('/')} />
      </Wrapper>
    );
  }

  if (mode === 'password') {
    return (
      <Wrapper>
        <h1 style={{ textAlign: 'center' }}>
          Email confirmation!
        </h1>

        <div style={{ padding: '16px 32px', textAlign: 'center' }}>
          In order to complete your sign-up process, please enter your password.
        </div>

        <Form
          layout="vertical"
          onFinish={({ password }) => {
            confirmEmail({
              secretKey: secretKey || '',
              password,
            });
          }}
        >
          <Form.Item
            name="password"
            label={`${t('password')} *`}
            rules={[validationRules]}
          >
            <Input.Password placeholder="Password" size="large" />
          </Form.Item>

          {response && !error ? (
            <Alert
              type="success"
              message="Signed in successfully!"
              style={{ margin: '8px 0 24px' }}
              closable
              onClose={() => confirmClear()}
            />
          ) : null}

          {error ? (
            <Alert
              style={{ margin: '8px 0 24px' }}
              type="error"
              message={getMessageInError(error)}
              closable
              onClose={confirmErrorClear}
            />
          ) : null}

          <Submit
            size="large"
          >
            Confirm
          </Submit>
        </Form>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <h1 style={{ textAlign: 'center', margin: '0' }}>
        Welcome to Finatium!
        {' '}
        {/* eslint-disable-next-line no-nested-ternary */}
        {error ? '' : (response || redirect ? 'successfully!' : null)}
      </h1>
      <div className={styles.welcomePage}>
        <span>You have successfully completed the first step towards improving your financial situation.</span>
        <img src="/media/welcome-logo.png" alt="welcome-logo" />
        <Button size="large" type="primary">Continue</Button>
      </div>
      {error ? (
        <Alert
          style={{ marginTop: 8 }}
          type="error"
          message={getMessageInError(error)}
          closable
          onClose={confirmErrorClear}
        />
      ) : null}
    </Wrapper>
  );
}

export default connect(null, {
  confirmEmail: confirmEmailAction,
  confirmClear: confirmClearAction,
  confirmErrorClear: confirmErrorClearAction,
})(ConfirmEmail);
