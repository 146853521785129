import React from 'react';
import { Layout as AntdLayout, Divider } from 'antd';
import { useLocation } from 'react-router-dom';
import ReduxRedirect from '../../Common/ReduxRedirect';

import styles from './index.module.scss';
import AuthSteps from '../AuthSteps';

interface SimpleProps {
  children: React.ReactNode
}

export default function Simple({ children }: SimpleProps): React.ReactNode {
  const location = useLocation();

  return (
    <>
      <ReduxRedirect />
      <AntdLayout>
        <div className={styles.signIn}>
          <div className={styles.leftInfo}>
            <div className={styles.header}>
              <img src="/media/finatium_logo.png" alt="logo" />
              <Divider style={{ backgroundColor: '#FFFFFF4D', marginTop: '44px' }} />
              <AuthSteps />
            </div>
            <div className={styles.footer}>
              <div className={styles.info}>
                <a href="#">Need Help?</a>
                <div>
                  <a href="#">Privacy Policy</a>
                </div>
                <a href="#">Term & Services</a>
              </div>
              <Divider style={{ backgroundColor: '#FFFFFF4D', marginTop: '32px' }} />
              <div className={styles.social}>
                <a href="#">
                  <img src="/media/social/linkedIn.png" alt="linkedIn" />
                </a>
                <a href="#">
                  <img src="/media/social/twitter.png" alt="twitter" />
                </a>
                <a href="#">
                  <img src="/media/social/youtube.png" alt="youtube" />
                </a>
                <a href="#">
                  <img src="/media/social/instagram.png" alt="instagram" />
                </a>
              </div>
            </div>
          </div>
          <AntdLayout.Content className={styles.content}>
            {children}
          </AntdLayout.Content>
        </div>
      </AntdLayout>
    </>
  );
}
