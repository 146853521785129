import React from 'react';
import { Provider } from 'react-redux';
import localeData from 'dayjs/plugin/localeData';
import dayjs from 'dayjs';
import './index.scss';

import { config } from '@triare/auth-redux';

import store from './store';
import Routes from './routes';
import AntdConfigProvider from './AntdConfigProvider';

const { displayName } = config;

dayjs.extend(localeData);

export default function App(): React.ReactNode {
  document.title = `${displayName}: Sign in`;
  // document.title = 'Finatium';

  return (
    <Provider store={store}>
      <AntdConfigProvider>
        <Routes />
      </AntdConfigProvider>
    </Provider>
  );
}
