// import React, { useState } from 'react';
// import { useTranslation } from 'react-i18next';
// import { useNavigate } from 'react-router-dom';
// import { Alert, Button, Input } from 'antd';
// import Wrapper from '../../Wrapper';

// import styles from './index.module.scss';

// function Information(): React.ReactNode {
//   const navigate = useNavigate();
//   const { t } = useTranslation();
//   const [isEditingEmail, setIsEditingEmail] = useState(false);
//   const [newEmail, setNewEmail] = useState('');
//   const handleEmailChange = () => {
//     console.log('Updated email:', newEmail);
//     setIsEditingEmail(false);
//   };

//   return (
//     <Wrapper>
//       <div className={styles.content}>
//         <h1>
//           Verify your email address
//         </h1>
//         <Alert
//           message="Verification link sent successfully!"
//           type="success"
//           showIcon
//           style={{ margin: '8px 0' }}
//         />
//         <div style={{ marginBottom: '4px' }}>
//           To continue, please click the verification link in the email we sent to olive.green@mail.com.
//         </div>
//         <div style={{ marginBottom: '8px' }}>
//           If you don&apos;t see this email in your inbox, you may need to check your spam folder.
//         </div>
//         <Button size="large" onClick={() => navigate('/')}>
//           Resend verification email
//         </Button>
//         {!isEditingEmail ? (
//           <Button size="large" type="link" onClick={() => setIsEditingEmail(true)}>
//             Change my email
//           </Button>
//         ) : (
//           <>
//             <span style={{ marginBottom: '8px' }}>Email</span>
//             <Input
//               type="email"
//               size="large"
//               placeholder="Enter new email"
//               value={newEmail}
//               onChange={(e) => setNewEmail(e.target.value)}
//               style={{ marginBottom: '10px' }}
//             />
//             <Button size="large" type="primary" onClick={handleEmailChange}>
//               Update email
//             </Button>
//           </>
//         )}
//       </div>
//     </Wrapper>
//   );
// }

// export default Information;
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  Button, Form, Form as AntdForm, Input,
} from 'antd';
import { connect } from 'react-redux';
import {
  signUpTwoFA as signUpTwoFAAction, SignUpTwoFAAction,
  signUpResendEmail as signUpResendEmailAction,
  SignUpResendEmailAction,
} from '@triare/auth-redux/dist/saga/auth/signUp';
import { Action } from '@triare/auth-redux';
import Wrapper from '../../Wrapper';
import { createRulesForAntd, validationSignUpChangeEmail } from '../../../../../utils/validations';
import { useAuth } from '../../../../../store/auth';
import Submit from '../../../../Common/Form/Button/Submit';

import styles from './index.module.scss';

const validationRules = createRulesForAntd(validationSignUpChangeEmail);

interface InformationProps {
  signUpResendEmail: (payload: SignUpResendEmailAction) => Action;
  signUpTwoFA: (payload: SignUpTwoFAAction) => Action;
}

function Information({
  signUpResendEmail, signUpTwoFA,
}: InformationProps): React.ReactNode {
  const {
    signUp: {
      data, loading, response, error, resendEmail,
    },
  } = useAuth();
  const navigate = useNavigate();
  const [state, setState] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (!loading && response && !error) {
      setState(false);
    }
  }, [loading, response, error]);

  return (
    <Wrapper>
      <div className={styles.content}>
        <h1>
          Verify your email address
        </h1>
        <div style={{ marginBottom: '4px' }}>
          To continue, please click the verification link in the email we sent to
          {' '}
          {data?.email}
          .
        </div>
        <div style={{ marginBottom: '8px' }}>
          If you don&apos;t see this email in your inbox, you may need to check your spam folder.
        </div>
        {/* <Button type="primary" onClick={() => navigate('/')}>
          Go to home page
        </Button>

        <br /> */}

        <Button
          // type="primary"
          size="large"
          loading={resendEmail.loading}
          onClick={() => (data && data.email ? signUpResendEmail({ email: data.email }) : undefined)}
        >
          Resend verification email
        </Button>

        {state ? (
          <Form<{ email: string }>
            autoComplete="off"
            layout="vertical"
            onFinish={({ email }) => {
              if (data) {
                signUpTwoFA({
                  ...data,
                  email,
                });
              }
            }}
          >
            <AntdForm.Item
              name="email"
              label={`${t('email')}`}
              rules={[validationRules]}
              style={{ marginBottom: 0 }}
            >
              <Input
                placeholder={t('email') || 'Email'}
                size="large"
                readOnly
                onFocus={(e) => e.currentTarget.removeAttribute('readonly')}
              />
            </AntdForm.Item>

            <Submit
              loading={loading}
              size="large"
            >
              Update email
            </Submit>
          </Form>
        ) : (
          <Button
            size="large"
            type="link"
            loading={resendEmail.loading}
            onClick={() => setState(true)}
          >
            Change my email
          </Button>
        )}
      </div>
    </Wrapper>
  );
}

export default connect(null, {
  signUpResendEmail: signUpResendEmailAction,
  signUpTwoFA: signUpTwoFAAction,
})(Information);
