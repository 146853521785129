import React, { useEffect, useState } from 'react';
import {
  Alert, Button, Form, FormInstance,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { AnyObject } from '@triare/auth-redux';
import { connect } from 'react-redux';
import {
  OTP as otpAction,
  OTPAction,
  OTPClear as otpClearAction,
  OTPGenerate as otpGenerateAction,
  OTPGenerateAction,
  OTPGenerateClear as otpGenerateClearAction,
  OTPGenerateResponse,
} from '@triare/auth-redux/dist/saga/auth/OTP';
import InputCodeWrapper from '../../Form/Input/Code';
import { moduleName } from '../../../../store/auth';
import { RootState } from '../../../../store';
import { timeResendOTPCode } from '../../../../contstant';
import { getMessageInError } from '../../../../hooks/fetch';
import Submit from '../../Form/Button/Submit';
import PhoneNumberInput from '../../PhoneNumberInput';

interface FormValues {
  [key: string]: number;
}

interface OTPVerificationProps {
  email?: string;
  nameBtnFinish: string;

  beforeBtnFinish?: React.ReactNode;
  afterBtnFinish?: React.ReactNode;
  className?: string;
  onInit?: (form: FormInstance) => void;

  OTP: (payload: OTPAction) => void;
  OTPClear: () => void;
  loading: boolean;
  error: null | AnyObject;

  OTPGenerate: (payload?: OTPGenerateAction) => void;
  OTPGenerateClear: () => void;
  otpGenerateLoading: boolean;
  otpGenerateResponse: OTPGenerateResponse | null;
  otpGenerateError: null | AnyObject;

  alertSuccess: React.ReactNode;

}

function OTPVerification({
  email,
  nameBtnFinish,
  alertSuccess,
  OTP,
  OTPClear,
  loading,
  error,
  OTPGenerate,
  OTPGenerateClear,
  otpGenerateLoading,
  otpGenerateResponse,
  otpGenerateError,
  className,
  onInit,
  beforeBtnFinish,
  afterBtnFinish,
}: OTPVerificationProps): React.ReactNode | null {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [resendClick, setResentClick] = useState(false);
  const [startTime, setStartTime] = useState(Date.now() - timeResendOTPCode);
  const [time, setTime] = useState(60);
  const calcTime = () => Math.round((startTime + timeResendOTPCode + 1 - Date.now()) / 1000);
  const [isEditingPfone, setIsEditingPhone] = useState(false);
  const [newPhone, setNewPhone] = useState('');
  const handlePhoneChange = () => {
    console.log('Updated email:', newPhone);
    setIsEditingPhone(false);
  };

  useEffect(() => {
    if (otpGenerateResponse && !otpGenerateError) {
      setStartTime(Date.now());
    }
  }, [otpGenerateResponse, otpGenerateError]);

  useEffect(() => {
    if (time > 0) {
      const timerId = setInterval(() => {
        setTime((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(timerId);
    }

    return () => {};
  }, [time]);

  useEffect(() => {
    if (resendClick) {
      const id = setInterval(() => {
        const seconds = calcTime();

        if (seconds > 0) {
          setTime(seconds);
        } else {
          setTime(0);
          clearInterval(id);
          setResentClick(false);
        }
      }, 1000);

      return () => clearInterval(id);
    }

    return () => {};
  }, [resendClick]);

  useEffect(() => {
    if (onInit) {
      onInit(form);
    }
  }, [form]);

  const onFinishHandler = (values: FormValues) => {
    OTP({ secretCode: Object.values(values).join('') });
  };

  return (
    <Form<FormValues> form={form} className={className} autoComplete="off" layout="vertical" onFinish={onFinishHandler}>
      <h1 style={{ margin: '0', textAlign: 'center' }}>Verify your phone number</h1>
      <span>
        To continue, please enter 4-digit verification code sent to +43 000 000 00 00
        {' '}
        {/* <a
          href="/generate-code"
          onClick={(e) => {
            e.preventDefault();

            const seconds = calcTime();

            if (seconds > 0) {
              setTime(seconds);
              setResentClick(true);
            } else {
              OTPGenerate();
            }
          }}
        >
          send a new code to your phone
        </a> */}
        {email ? (
          <>
            {' '}
            or
            {' '}
            <a
              href="/generate-code"
              onClick={(e) => {
                e.preventDefault();

                const seconds = calcTime();

                if (seconds > 0) {
                  setTime(seconds);
                  setResentClick(true);
                } else {
                  OTPGenerate({
                    useEmail: true,
                  });
                }
              }}
            >
              send it to
              {' '}
              {email}
            </a>
          </>
        ) : null}
        {' '}

        {resendClick ? (
          <div style={{ color: 'red', padding: '8px 0' }}>
            Wait
            {' '}
            {time}
            {' '}
            seconds and then you can get a new code.
          </div>
        ) : null}
      </span>
      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '14px' }}>
        Enter the 4-digit code
      </div>
      <InputCodeWrapper form={form} />
      <div style={{ color: '#000000A6', padding: '8px 0', textAlign: 'center' }}>
        Resend verification code in
        {' '}
        {time}
      </div>
      {error ? (
        <Alert
          type="error"
          message={getMessageInError(error)}
          closable
          onClose={OTPClear}
          style={{ marginBottom: 16 }}
        />
      ) : null}

      {otpGenerateError ? (
        <Alert
          type="error"
          message={getMessageInError(otpGenerateError)}
          closable
          onClose={OTPGenerateClear}
          style={{ marginBottom: 16 }}
        />
      ) : null}

      {alertSuccess}

      <div>
        {beforeBtnFinish}

        <Submit
          loading={loading || otpGenerateLoading}
          size="large"
        >
          {nameBtnFinish}
        </Submit>

        {afterBtnFinish}
      </div>
      {/* <div>
        <Button type="link" size="large">Change my phone number</Button>
      </div> */}
      {!isEditingPfone ? (
        <Button size="large" type="link" onClick={() => setIsEditingPhone(true)}>
          Change my phone number
        </Button>
      ) : (
        <>
          <span style={{ marginBottom: '8px' }}>Phone Number</span>
          <PhoneNumberInput
            type="email"
            size="large"
            onChange={(e) => setNewPhone(e)}
            style={{ marginBottom: '10px' }}
          />
          <Button size="large" type="primary" onClick={handlePhoneChange}>
            Update email
          </Button>
        </>
      )}
    </Form>
  );
}

export default connect(
  (state: RootState) => ({
    error: state[moduleName].OTP.error,
    loading: state[moduleName].OTP.loading,
    otpGenerateResponse: state[moduleName].OTP.generate.response,
    otpGenerateError: state[moduleName].OTP.generate.error,
    otpGenerateLoading: state[moduleName].OTP.generate.loading,
  }),
  {
    OTP: otpAction,
    OTPClear: otpClearAction,
    OTPGenerate: otpGenerateAction,
    OTPGenerateClear: otpGenerateClearAction,
  },
)(OTPVerification);
