import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { Input, Select } from 'antd';
import parsePhoneNumber, {
  CountryCode, getCountries, getCountryCallingCode,
} from 'libphonenumber-js';
import Flag from 'country-flag-icons/react/3x2';
import { useTranslation } from 'react-i18next';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { defaultPhoneCountry } from '../../../contstant';

import styles from './index.module.scss';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface PhoneNumberFormItemProps extends React.ComponentProps<any> {
  size?: SizeType
  value?: string
  onChange?: (value: string) => void
}

const countryOptions: { value: string; label: React.ReactElement }[] = getCountries().map((code) => {
  const FlagElement = Flag[code];

  return {
    key: code,
    value: code,
    label: (
      <div className={styles.options}>
        <FlagElement className={styles.flag} />
        {/* {code} */}
      </div>
    ),
    style: {
      padding: '5px 8px',
    },
  };
});

const DefaultFlagElement = Flag[defaultPhoneCountry];

const getPhoneNumber = (() => {
  let result: string;
  let beforeValue: string;
  let beforeCountryCode: CountryCode;

  return (value: string, countryCode: CountryCode): string => {
    if (beforeValue === value && beforeCountryCode === countryCode) {
      return result;
    }

    beforeValue = value;
    beforeCountryCode = countryCode;
    const countryNumber = getCountryCallingCode(countryCode);

    let phoneNumber = `${value}`.replace(/\D*/gim, '');

    if (phoneNumber.indexOf(countryNumber) === 0) {
      phoneNumber = phoneNumber.slice(countryNumber.length);
    } else if (countryNumber.indexOf(phoneNumber) === 0) {
      phoneNumber = phoneNumber.slice(countryNumber.length);
    }

    result = parsePhoneNumber(phoneNumber, countryCode)?.number || `+${countryNumber}${phoneNumber}`;

    return result;
  };
})();

export function PhoneNumberInput({
  size = 'middle', value = '', onChange = () => {}, ...props
}: PhoneNumberFormItemProps): React.ReactNode {
  const { t } = useTranslation();
  const [countryCode, setCountryCode] = useState<CountryCode>(defaultPhoneCountry);

  // console.log(getCountryCallingCode(countryCode));

  const findAndSetCountryCode = useCallback((phone: string) => {
    if (phone) {
      const data = parsePhoneNumber(phone, countryCode);

      if (data && data.country) {
        setCountryCode(data.country);
      }
    }
  }, [setCountryCode]);

  useEffect(() => {
    if (typeof value === 'string') {
      findAndSetCountryCode(value);
    }
  }, [value]);

  const numberInputChanged = useCallback((phone: string) => {
    if (typeof onChange === 'function') {
      onChange(getPhoneNumber(phone, countryCode));
    }
  }, [onChange, countryCode]);

  const selectCountry = useCallback((code: CountryCode) => {
    // const beforeCountryCode = countryCode;
    // const beforePhoneCode = `+${getCountryCallingCode(beforeCountryCode)}`;

    setCountryCode(code);
    if (onChange) onChange('');
    //
    // console.log(getPhoneNumber(value, beforeCountryCode), beforePhoneCode);
    //
    // if (onChange && (value === '' || getPhoneNumber(value, beforeCountryCode) === beforePhoneCode)) {
    //   onChange(`+${getCountryCallingCode(code)}`);
    // }
  }, [setCountryCode, onChange, value]);

  /** Display phone number without Country Code */
  const valueWithoutCode = useMemo(() => (
    getPhoneNumber(value, countryCode).replace(`+${getCountryCallingCode(countryCode)}`, '')
  ), [value, countryCode]);

  return (
    <Input
      {...props}
      size={size}
      className={styles.inputPhone}
      onChange={(e) => numberInputChanged(e.target.value)}
      value={valueWithoutCode}
      readOnly
      onFocus={(e) => e.currentTarget.removeAttribute('readonly')}
      prefix={`+${getCountryCallingCode(countryCode)}`}
      addonBefore={(
        <Select
          size={size}
          showSearch
          options={countryOptions}
          onSelect={selectCountry}
          value={countryCode}
          placeholder={<DefaultFlagElement className={styles.flag} />}
          className={styles.select}
        />
      )}
    />
  );
}

export default PhoneNumberInput;
