import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import {
  Alert, Button, Form as AntdForm, Input, Typography,
} from 'antd';
import { connect } from 'react-redux';
import {
  forgotPassword as forgotPasswordAction,
  forgotPasswordErrorClear as forgotPasswordErrorClearAction,
  ForgotPasswordResponse,
  ForgotPasswordAction,
} from '@triare/auth-redux/dist/saga/auth/forgotPassword';
import { Error } from '@triare/auth-redux/dist/saga/common';
import { Action } from '@triare/auth-redux';
import { validationSignInEmailAndPassword, createRulesForAntd } from '../../../../utils/validations';
import SendSuccess from './SendSuccess';
import { getMessageInError } from '../../../../hooks/fetch';
import Wrapper from '../Wrapper';
import Submit from '../../../Common/Form/Button/Submit';
import { RootState } from '../../../../store';
import { moduleName } from '../../../../store/auth';

import styles from './index.module.scss';

const { Text, Paragraph } = Typography;
const validationRules = createRulesForAntd(validationSignInEmailAndPassword);

interface ForgotPasswordProps {
  loading: boolean;
  response: ForgotPasswordResponse | null;
  error: Error;
  forgotPassword: (payload: ForgotPasswordAction) => Action;
  forgotPasswordErrorClear: () => Action;
}

function ForgotPassword({
  forgotPassword, forgotPasswordErrorClear, loading, response, error,
}: ForgotPasswordProps) {
  const [sendSuccess, setSendSuccess] = useState(false);
  const [enteredEmail, setEnteredEmail] = useState('');

  const onFinish = ({ email }: { email: string }) => {
    forgotPassword({ email });
    setEnteredEmail(email);
  };

  useEffect(() => {
    if (response) {
      setSendSuccess(true);
    }
  }, [response]);
  const resendEmail = () => {
    if (enteredEmail) {
      forgotPassword({ email: enteredEmail });
    }
  };

  return (
    <Wrapper>
      <AntdForm className={styles.form} layout="vertical" onFinish={onFinish}>
        {sendSuccess ? (
          <SendSuccess email={enteredEmail} onResend={resendEmail} />
        ) : (
          <>
            <h1 style={{ margin: '0' }}>Forgot your password?</h1>
            <span>
              Please enter the email address associated with your account, and
              we&apos;ll send you a verification link to reset your password
            </span>

            {error && (
              <AntdForm.Item>
                <Alert
                  type="error"
                  onClose={() => forgotPasswordErrorClear()}
                  message={getMessageInError(error)}
                  closable
                  showIcon
                />
              </AntdForm.Item>
            )}

            <AntdForm.Item name="email" label="Email" rules={[validationRules]} style={{ marginTop: '16px' }}>
              <Input size="large" placeholder="Email" />
            </AntdForm.Item>

            <Submit
              loading={loading}
              size="large"
              block
            >
              Request password reset
            </Submit>

            <AntdForm.Item>
              <Link to="/sign-in">
                <Button type="link" size="large" block>
                  Back to log in
                </Button>
              </Link>
            </AntdForm.Item>

            {/* for test */}
            {/* eslint-disable-next-line react/button-has-type */}
            <button onClick={() => setSendSuccess(true)}>
              test button
              {' '}
              <br />
              {' '}
              see what happens after a successful request
            </button>
          </>
        )}
      </AntdForm>
    </Wrapper>
  );
}

export default connect((state: RootState) => ({
  loading: state[moduleName].forgotPassword.loading,
  response: state[moduleName].forgotPassword.response,
  error: state[moduleName].forgotPassword.error,
}), {
  forgotPassword: forgotPasswordAction,
  forgotPasswordErrorClear: forgotPasswordErrorClearAction,
})(ForgotPassword);
